define("discourse/plugins/discourse-group-directory/discourse/controllers/consultants-index", ["exports", "discourse/controllers/group-index", "discourse-common/utils/decorators", "discourse/models/user", "@ember/runloop", "@ember/object/computed", "@ember/object", "discourse/lib/debounce", "discourse-common/config/environment"], function (_exports, _groupIndex, _decorators, _user, _runloop, _computed, _object, _debounce, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj, _init;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const filterableFields = ['name', 'username', 'company', 'consult_language', 'bio_cooked', 'location'];
  var _default = _exports.default = _groupIndex.default.extend((_dec = (0, _decorators.default)("order", "desc", "filter"), _dec2 = (0, _decorators.observes)('filter'), (_obj = {
    sortedMembers: (0, _computed.sort)('model.members', 'sortProperties'),
    isConsultantsPage: (0, _computed.equal)('model.name', 'consultants'),
    sortProperties() {
      return [this.siteSettings.rstudio_consultant_var_01_field + ':desc'];
    },
    memberParams(order, desc, filter) {
      return {
        order,
        desc,
        filter
      };
    },
    filterMembers: (0, _debounce.default)(function () {
      this.set("loading", true);
      const memberCache = this.get("memberCache");
      const filter = this.get("filter");
      if (memberCache) {
        let users = memberCache.filter(user => {
          return !filter || filterableFields.any(attr => {
            return user[attr] && user[attr].toLowerCase().includes(filter.toLowerCase());
          });
        });
        this.set("model.members", users);
      }
      this.set("loading", false);
    }, _environment.INPUT_DELAY),
    findConsultants() {
      if (!this.currentUser || this.currentUser && !this.currentUser.admin) {
        (0, _runloop.scheduleOnce)('afterRender', this, function () {
          $(`.user-content-wrapper`).addClass("non-admin");
        });
      }
      this.set("loading", true);
      const model = this.get("model");
      this.model.reloadMembers(this.memberParams, true).then(() => {
        ['members', 'owners'].forEach(k => {
          if (model[k] === null || model[k] === undefined) {
            model.set(k, 0);
          }
        });
        this.set("application.showFooter", model.members.length >= model.user_count - model.owners.length);
        this.set("loading", false);
        this.set("memberCache", model.members);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "sortProperties", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "sortProperties"), _obj), _applyDecoratedDescriptor(_obj, "memberParams", [_dec], Object.getOwnPropertyDescriptor(_obj, "memberParams"), _obj), _applyDecoratedDescriptor(_obj, "filterMembers", [_dec2], (_init = Object.getOwnPropertyDescriptor(_obj, "filterMembers"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function () {
      return _init;
    }
  }), _obj)), _obj)));
});